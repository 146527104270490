import { memo } from 'react'
import isEqual from 'lodash/isEqual'
import dynamic from 'next/dynamic'

import { TContentfulComponents } from 'types/contentful/contentfulComponents'
import { Spacer } from './ConfigurableSpacer/Spacer'
import { stringToHash } from '@utils/contentful/stringToHash'
import EverestVisibilityDetector from '@components/common/EverestVisibilityDetector'
import { ConfigurableMarketingWidget } from './ConfigurableMarketingWidget/ConfigurableMarketingWidget'
import { ConfigurableEmbed } from './ConfigurableEmbed'

export const FIRST_COMPONENT_POSITION_OUTSIDE_VIEW = 5

const DynamicConfigurableList = dynamic(
  () => import('@components/ContentfulPage/ConfigurableList/ConfigurableList')
)
const DynamicStandaloneHorizontalFeature = dynamic(
  () =>
    import(
      '@components/ContentfulPage/ConfigurableFeatures/StandaloneHorizontalFeature/StandaloneHorizontalFeature'
    )
)
const DynamicInTheWild = dynamic(
  () => import('@components/category/InTheWild/InTheWild')
)
const DynamicConfigurableDescriptionList = dynamic(
  () => import('./ConfigurableDescriptionList/ConfigurableDescriptionList')
)
const DynamicConfigurableCard = dynamic(() => import('./ConfigurableCard'))
const DynamicConfigurableSlider = dynamic(() => import('./ConfigurableSlider'))
const DynamicConfigurableTileList = dynamic(
  () => import('./ConfigurableTileList')
)
const DynamicConfigurableTile = dynamic(() => import('./ConfigurableTile'))

function NeedMemoContentfulPage({
  componentsProps,
  customCTAsMapper,
  onScrollProgress,
  applyStaticGeneration = false,
}: {
  componentsProps: TContentfulComponents
  customCTAsMapper?: any
  onScrollProgress?: (any) => void
  applyStaticGeneration?: boolean
}) {
  const componentMapper = {
    featuresList: DynamicConfigurableList,
    feature: DynamicStandaloneHorizontalFeature,
    wild: DynamicInTheWild,
    spacer: Spacer,
    configurableDescriptionList: DynamicConfigurableDescriptionList,
    configurableCard: DynamicConfigurableCard,
    configurableSlider: DynamicConfigurableSlider,
    configurableTileList: DynamicConfigurableTileList,
    configurableTile: DynamicConfigurableTile,
    configurableMarketingBanner: ConfigurableMarketingWidget,
    configurableEmbed: ConfigurableEmbed,
  }

  const onContentfulComponentCTA = ({ ctaId }) => {
    if (customCTAsMapper[ctaId]) {
      customCTAsMapper[ctaId]()
    }
  }

  const updateFocusedFeature = (submenuLabel) => {
    if (submenuLabel && onScrollProgress) {
      onScrollProgress(submenuLabel)
    }
  }

  return (
    <>
      {componentsProps &&
        componentsProps.map((component, index) => {
          const MappedComponent = componentMapper[component.componentKey]
          return (
            <EverestVisibilityDetector
              onVisible={() => updateFocusedFeature(component.submenuLabel)}
              offset={{ top: 200 }}
              key={component?.componentKey + component?.id + index}
            >
              <div
                className="relative"
                id={
                  component?.submenuLabel
                    ? stringToHash(component.submenuLabel)
                    : ''
                }
              >
                <MappedComponent
                  {...component}
                  onCtaAction={onContentfulComponentCTA}
                  generateStatically={
                    applyStaticGeneration &&
                    index < FIRST_COMPONENT_POSITION_OUTSIDE_VIEW
                  }
                />
              </div>
            </EverestVisibilityDetector>
          )
        })}
    </>
  )
}

const areEqual = (prevProps, nextProps) => {
  return isEqual(prevProps.componentsProps, nextProps.componentsProps)
}

export const ContentfulPage = memo(NeedMemoContentfulPage, areEqual)
