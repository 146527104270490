export interface ISpacer {
  id: string
  componentKey: string
  size: 'xs' | 's' | 'm' | 'lg'
  backgroundColor: string
}

export type TConfigurableSpacerSize = '24px' | '32px' | '48px' | '64px' | '80px'

export function Spacer({ size, backgroundColor }: ISpacer) {
  const sizeToClassMapper: { [key in TConfigurableSpacerSize]: string } = {
    '24px': 'h-[24px]',
    '32px': 'h-[32px]',
    '48px': 'h-[48px]',
    '64px': 'h-[64px]',
    '80px': 'h-[80px]',
  }

  return (
    <div
      className={`w-full ${
        sizeToClassMapper[size] || sizeToClassMapper['32px']
      }`}
      style={{ backgroundColor: backgroundColor || '#FFFFFF' }}
    />
  )
}
