import { ReactElement } from 'react-markdown'
import { BasicMarketingForm } from './MarketingForm'
import { TConsentOptions } from '@utils/form/schema'
import { IFormField } from '@noissue-ui-kit/oldBranding/form'
import { useState } from 'react'
import * as Sentry from '@sentry/nextjs'
import { MARKETING_FORM_API, formValuesToHubspotPayload } from '@utils/hubspot'

interface IMarketingPopupContent {
  marketingForm: IFormField[]
  marketingFormId: string
  consentOptions: TConsentOptions
  onError: () => void
  onSuccess?: () => void
}

export function MarketingPopupContent({
  marketingForm,
  marketingFormId,
  consentOptions,
  onSuccess,
  onError,
}: IMarketingPopupContent): ReactElement {
  const [processing, setProcessing] = useState<boolean>(false)

  if (!marketingForm || !marketingFormId) {
    return null
  }

  const onSubmit = async (formValues) => {
    try {
      setProcessing(true)
      const formPayload = formValuesToHubspotPayload({
        formValues,
        formId: marketingFormId,
        consentOptions,
      })

      await fetch(MARKETING_FORM_API, {
        method: 'POST',
        body: JSON.stringify(formPayload),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      onSuccess()
    } catch (err) {
      onError()
      Sentry.captureException(`Marketing form submission failed. Error:`, {
        contexts: {
          error: {
            err,
          },
          details: {
            formId: marketingFormId,
            formValues,
          },
        },
      })
    } finally {
      setProcessing(false)
    }
  }

  return (
    <BasicMarketingForm
      onSubmit={onSubmit}
      fields={marketingForm}
      processing={processing}
    />
  )
}
