import { useState, useRef, ChangeEvent, useEffect } from 'react'
import { TInputValidator, getFieldError } from '../formInput/FormInput.utils'
import { useMatchValueInput } from './useMatchValueInput'
import {
  HiOutlineCheckCircle,
  HiOutlineXCircle,
  HiOutlineEyeOff,
  HiOutlineEye,
} from 'react-icons/hi'

interface IFormConfirmationInput {
  name: string
  label?: string
  type: string
  placeholder?: string
  wasSubmitted: boolean
  validators: TInputValidator[]
  className?: string
  fieldHint?: string
  initialValue?: string
  hasSuccessState?: boolean
  hasValidationIcons?: boolean
  IconBefore?: React.ElementType
  IconAfter?: React.ElementType
  fieldToMatch: string
  matchingError: string
  onValidationError?: ({ error }: { error: string }) => void
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

export function ConfirmationFormInput({
  name,
  label,
  type,
  placeholder,
  wasSubmitted,
  validators,
  className,
  initialValue,
  hasSuccessState = false,
  hasValidationIcons = false,
  IconBefore,
  IconAfter,
  fieldHint,
  fieldToMatch,
  matchingError,
  onValidationError = () => {},
  onChange = () => {},
}: IFormConfirmationInput) {
  const ref = useRef<HTMLInputElement>(null)
  const valueToMatch = useMatchValueInput(ref, fieldToMatch)
  const [value, setValue] = useState(initialValue || '')
  const [touched, setTouched] = useState(false)
  const [touching, setTouching] = useState(false)
  const [alternativeType, setAlternativeType] = useState('')
  const errorMessage =
    getFieldError(value, validators) ||
    (valueToMatch !== value ? matchingError : null)

  const displayErrorMessage = (wasSubmitted || touched) && errorMessage
  const displaySuccessState =
    hasSuccessState &&
    (wasSubmitted || touched) &&
    !errorMessage &&
    !touching &&
    value
  const validated = displayErrorMessage || displaySuccessState
  const displayHint = fieldHint && !validated && !(touched && value)

  const iconColorClass = () => {
    if (validated) {
      return displaySuccessState ? 'text-core-tree' : 'text-error'
    }
    if (touching || (touched && value)) {
      return 'text-black'
    }
    return 'text-core-grey-mild '
  }

  const togglePasswordVisibility = () => {
    if (!alternativeType) {
      setAlternativeType('text')
    } else {
      setAlternativeType('')
    }
  }

  useEffect(() => {
    if (!onValidationError || typeof onValidationError !== 'function') {
      return
    }

    onValidationError({
      error: errorMessage || null,
    })
  }, [errorMessage])

  return (
    <div
      key={name}
      className={`
        flex flex-col items-start justify-items-center content-start relative text-base-16 transition-all w-full mt-2 mb-4
        ${className}
      `}
    >
      {label && <label htmlFor={`${name}-input`}>{label}</label>}

      <div className="h-24 relative w-full mb-2">
        <input
          ref={ref}
          id={`${name}-input`}
          aria-label={`${name}-input`}
          name={name}
          type={alternativeType || type}
          value={value}
          onChange={(event) => {
            setValue(event.currentTarget.value)
            onChange(event)
          }}
          onBlur={() => {
            setTouched(true)
            setTouching(false)
          }}
          onFocus={() => setTouching(true)}
          placeholder={placeholder}
          aria-describedby={displayErrorMessage ? `${name}-error` : undefined}
          className={`
            w-full h-full pl-10 border-2 outline-0 outline-none shadow-none rounded-full font-mori placeholder-black-60
            ${
              displayErrorMessage
                ? 'border-error text-error placeholder-error'
                : 'border-black-20 focus:border-acai-60 text-black'
            }
            ${
              displaySuccessState
                ? 'border-core-tree text-core-tree'
                : 'border-black-20 focus:border-acai-60 text-black'
            }
            ${IconBefore ? 'pl-20' : ''}
            ${IconBefore ? 'pr-20' : ''}
            transition-all
          `}
        />

        {IconBefore && (
          <div
            className={`absolute w-10 h-10 text-4xl left-7 top-1/2 -translate-y-1/2 ${iconColorClass()}`}
          >
            <IconBefore />
          </div>
        )}

        {!(validated && hasValidationIcons) &&
          IconAfter &&
          type !== 'password' && (
            <div
              className={`absolute w-10 h-10 text-4xl right-7 top-1/2 -translate-y-1/2 ${iconColorClass()}`}
            >
              <IconAfter />
            </div>
          )}

        {hasValidationIcons && hasSuccessState && displaySuccessState && (
          <div className="absolute w-10 h-10 text-4xl right-7 text-core-tree top-1/2 -translate-y-1/2">
            <HiOutlineCheckCircle />
          </div>
        )}

        {hasValidationIcons && displayErrorMessage && (
          <div className="absolute w-10 h-10 text-4xl right-7 text-error top-1/2 -translate-y-1/2">
            <HiOutlineXCircle />
          </div>
        )}

        {type === 'password' && !(validated && hasValidationIcons) && (
          <div
            className={`absolute w-10 h-10 text-4xl right-7 cursor-pointer top-1/2 -translate-y-1/2 ${iconColorClass()}`}
            onClick={togglePasswordVisibility}
          >
            {alternativeType ? <HiOutlineEyeOff /> : <HiOutlineEye />}
          </div>
        )}
      </div>

      {displayErrorMessage && (
        <span
          role="alert"
          id={`${name}-error`}
          className="pt-1 text-lg text-left text-error"
        >
          {errorMessage}
        </span>
      )}

      {displayHint && (
        <span
          id={`${name}-hint`}
          className={`pt-1 text-lg text-left ${
            touching ? 'text-core-grey-purple' : 'text-core-grey-mild'
          }`}
        >
          {fieldHint}
        </span>
      )}
    </div>
  )
}
