import React, { useState, ReactNode, ReactElement } from 'react'
import { ConfirmationFormInput } from '../confirmationFormInput'
import { FormCheckbox } from '../formCheckbox'
import { FormInput } from '../formInput'
import {
  fieldHasMatchError,
  filedHasCustomError,
  getFieldError,
  TInputValidator,
} from '../formInput/FormInput.utils'
import { onChange } from '@builder.io/react'

export interface IFormField {
  name: string
  type: string
  placeholder?: string
  label?: string
  description?: string
  fieldToMatch?: string
  matchingError?: string
  validators: TInputValidator[]
  initialValue?: string | boolean
  hasSuccessState?: boolean
  hasValidationIcons?: boolean
  fieldHint?: string
  className?: string
  IconBefore?: React.ElementType
  IconAfter?: React.ElementType
  customValidator?: (value) => Promise<string>
}

interface IForm {
  onSubmit: (object) => void
  onChange?: ({ values, isFormValid }) => void
  fields?: IFormField[]
  className?: string
  children: ReactNode
}

const extractPropsFromFieldsElements = (formItems) => {
  return formItems
    .map((item) => {
      if (React.isValidElement(item)) {
        return item?.props
      }
      return null
    })
    .filter((x) => x.name && (x.validators || x.fieldToMatch))
}

export function Form({
  className,
  onSubmit,
  onChange,
  fields = [],
  children,
}: IForm) {
  const [wasSubmitted, setWasSubmitted] = useState(false)
  const [formIsValid, setFormIsValid] = useState<boolean>(false)
  const dataDrivenMode = !!fields.length
  const formItems = React.Children.toArray(children)

  const isFormValid = async (fieldValues): Promise<boolean> => {
    let fieldsData = []

    if (dataDrivenMode) {
      fieldsData = fields
    } else {
      fieldsData = extractPropsFromFieldsElements(formItems)
    }

    const validityPerField: boolean[] = await Promise.all(
      fieldsData.map(async (field): Promise<boolean> => {
        if (getFieldError(fieldValues[field.name], field.validators)) {
          return false
        }
        if (fieldHasMatchError(field, fieldValues)) {
          return false
        }
        const hasCustomError = await filedHasCustomError(
          fieldValues[field.name],
          field.customValidator
        )
        if (hasCustomError) {
          return false
        }
        return true
      })
    )
    return validityPerField.every((x) => x)
  }

  const getFieldValues = (event: React.FormEvent<HTMLFormElement>) => {
    const formData = new FormData(event.currentTarget)
    const fieldValues = Object.fromEntries(formData.entries())
    return fieldValues
  }

  const onFormChange = async (event: React.FormEvent<HTMLFormElement>) => {
    const fieldValues = getFieldValues(event)
    const formIsValid = await isFormValid(fieldValues)
    setFormIsValid(formIsValid)

    typeof onChange === 'function'
      ? onChange({
          values: fieldValues,
          isFormValid: formIsValid,
        })
      : () => null
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setWasSubmitted(true)
    const fieldValues = getFieldValues(event)
    const formIsValid = await isFormValid(fieldValues)

    if (formIsValid) {
      onSubmit(fieldValues)
    }
  }

  return (
    <form
      noValidate
      onSubmit={handleSubmit}
      onChange={onFormChange}
      className={`w-full flex flex-wrap justify-between ${className}`}
    >
      {dataDrivenMode
        ? fields.map((field) => {
            if (field.type === 'checkbox') {
              return (
                <FormCheckbox
                  key={field.name}
                  {...field}
                  initialValue={Boolean(field.initialValue)}
                  wasSubmitted={wasSubmitted}
                />
              )
            } else if (field.fieldToMatch) {
              return (
                <ConfirmationFormInput
                  key={field.name}
                  {...field}
                  fieldToMatch={field.fieldToMatch}
                  matchingError={
                    field.matchingError ||
                    'Value doesn`t match one provided above'
                  }
                  initialValue={field.initialValue?.toString()}
                  wasSubmitted={wasSubmitted}
                />
              )
            } else {
              return (
                <FormInput
                  key={field.name}
                  {...field}
                  initialValue={field.initialValue?.toString()}
                  wasSubmitted={wasSubmitted}
                />
              )
            }
          })
        : formItems.map((child: ReactElement) => {
            return React.cloneElement(child, {
              wasSubmitted: wasSubmitted,
            })
          })}

      {dataDrivenMode && children}
    </form>
  )
}
