import { ReactElement } from 'react-markdown'
import { IConfigurbaleMarketingWidget } from './ConfigurableMarketingWidget.types'
import { ConfigurableText } from '../ConfigurableText/ConfigurableText'
import { ConfigurableCta } from '../ConfigurableCta'
import classnames from 'classnames'
import { mapBackgroundColorToClass } from '../contentfulPage.utils'
import { Modal } from '@noissue-ui-kit/modal'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { removeQueryParams } from '@utils/utilities'
import { ContentfulMarkdown } from '@utils/contentful/ContentfulMarkdown'
import { MarketingPopupContent } from './MarketingPopupContent/MarketingPopupContent'
import { Notification } from '@noissue-ui-kit/notification'
import { BadgeAlertMessage } from '@noissue-ui-kit/badgeAlertMessage'
import NellyRecommendsLogo from '../../../public/common/nellie-outlined-icon.svg'

export function ConfigurableMarketingWidget({
  bannerTitle,
  bannerTitleConfig,
  bannerCta,
  bannerBackgroundColor,
  popupTitle,
  popupText,
  popupMarketingForm,
  popupMarketingFormId,
  popupControlQueryParameter,
  popupDisclaimerText,
  popupSuccessStateMessage,
  popupMarketingFormConsentOptions,
}: IConfigurbaleMarketingWidget): ReactElement {
  const [showPopup, setShowPopup] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)
  const [showNotification, setShowNotification] = useState<boolean>(false)
  const router = useRouter()
  const query = router.query

  if (!bannerTitle && !bannerCta) {
    return null
  }

  useEffect(() => {
    if (
      query[popupControlQueryParameter] &&
      query[popupControlQueryParameter] === 'open'
    ) {
      setShowPopup(true)
      removeQueryParams({
        paramNames: [popupControlQueryParameter, 'slug'],
        path: router?.asPath,
        query: router?.query as unknown as string,
      })
    }
  }, [query])

  const closePopup = () => {
    setShowError(false)
    setShowPopup(false)
  }

  return (
    <section
      className={classnames(
        'w-full flex flex-col lg:flex-row justify-center items-center py-14 px-20',
        mapBackgroundColorToClass[bannerBackgroundColor]
      )}
    >
      <ConfigurableText
        {...bannerTitleConfig}
        className="mr-0 mb-10 lg:mr-14 lg:mb-0 mb"
      >
        {bannerTitle}
      </ConfigurableText>

      <ConfigurableCta {...bannerCta} onClick={() => setShowPopup(true)} />

      <Modal
        title={popupTitle}
        size={'small'}
        opened={showPopup}
        showControls={{
          cancelButton: false,
          confirmButton: false,
          cancelIcon: true,
        }}
        onCancel={() => closePopup()}
      >
        {popupText && (
          <ContentfulMarkdown overrideClass="text-pretty text-[16px]">
            {popupText}
          </ContentfulMarkdown>
        )}

        {showError && (
          <BadgeAlertMessage
            badge={{
              url: NellyRecommendsLogo,
              alt: 'Nelly Icon',
            }}
            size={'s'}
            borderType="square-round"
            className="my-6"
            variant="error"
          >
            Ooops! We couldn't process yoru request. Please try again later or
            contact us at&nbsp;
            <a href="mailto:" className="font-semibold text-pitaya">
              support@noissue.co
            </a>
          </BadgeAlertMessage>
        )}

        <MarketingPopupContent
          marketingForm={popupMarketingForm}
          marketingFormId={popupMarketingFormId}
          consentOptions={popupMarketingFormConsentOptions}
          onError={() => setShowError(true)}
          onSuccess={() => {
            closePopup()
            setShowNotification(true)
          }}
        />

        {popupDisclaimerText && (
          <ContentfulMarkdown
            overrideClass="!text-black-80 text-pretty text-[12px]"
            overrideStyles={{
              a: ({ children, href }) => (
                <a
                  className="text-black text-[12px] pointer-events-auto"
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {` ${children} `}
                </a>
              ),
            }}
          >
            {popupDisclaimerText}
          </ContentfulMarkdown>
        )}
      </Modal>

      <Notification
        variant="infoInverted"
        opened={showNotification}
        message={popupSuccessStateMessage}
        close={() => setShowNotification(false)}
      />
    </section>
  )
}
