import { useState, useEffect } from 'react'

export function useMatchValueInput(
  ref: React.RefObject<HTMLInputElement>,
  inputToMatchName: string
) {
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    const node = ref.current
    if (node === null) {
      return
    }

    const form = node?.closest('form')
    const inputs = form?.getElementsByTagName('input')

    if (inputs === undefined) {
      throw new Error(`This element must be a decendent of a form`)
    }

    let sourceInput: HTMLInputElement | undefined

    for (const element of Array.from(inputs)) {
      if (element instanceof HTMLInputElement) {
        if (element.name === inputToMatchName) {
          sourceInput = element
        }
      }
    }

    if (sourceInput === undefined) {
      throw new Error(`No input found with the name ${inputToMatchName}`)
    } else {
      setInputValue(sourceInput.value)
    }

    const handleChange = () => {
      if (sourceInput === undefined) {
        return
      }
      setInputValue(sourceInput.value)
    }
    sourceInput.addEventListener('change', handleChange)

    return () => {
      if (sourceInput === undefined) {
        return
      }
      sourceInput.removeEventListener('change', handleChange)
    }
  })

  return inputValue
}
