import {
  HUBSPOT_EMBED_EVENT,
  HUBSPOT_LP_ORIGIN,
} from '@utils/client-side-config'
import { useEffect, useRef, useState } from 'react'

export function ConfigurableEmbed({
  embedSourceUrl,
}: {
  embedSourceUrl: string
}) {
  const [iframeHeight, setIframeHeight] = useState<string | number>(0)
  const iframeRef = useRef(null)

  useEffect(() => {
    const onMessage = (event) => {
      if (
        event?.origin !== HUBSPOT_LP_ORIGIN ||
        event?.data?.message !== HUBSPOT_EMBED_EVENT ||
        // making sure that we listen to event generated by exactly this embed
        // useful when there are several embeds on the page
        !embedSourceUrl?.includes(event?.data?.path)
      ) {
        return
      }
      setIframeHeight(event?.data?.height || 'auto')
    }

    window.addEventListener('message', onMessage)
    return () => {
      window.removeEventListener('message', onMessage)
    }
  }, [])

  return (
    <iframe
      ref={iframeRef}
      src={embedSourceUrl}
      height={iframeHeight ? iframeHeight : 'auto'}
      className="w-full min-h-[40vh] no-scrollbar"
    />
  )
}
