import { Button } from '@noissue-ui-kit/button'
import { Form, IFormField } from '@noissue-ui-kit/form/form'
import { ReactElement } from 'react-markdown'
import classnames from 'classnames'
import { useState } from 'react'

interface IMarketingForm {
  fields: IFormField[]
  processing?: boolean
  onSubmit: (values) => void
}

// The form fields are parsed in /utils/contentful/contentfulComponents/parsers/parseMarketingBanner
export function BasicMarketingForm({
  fields,
  processing,
  onSubmit,
}: IMarketingForm): ReactElement {
  const [isFormValid, setIsFormValid] = useState<boolean>(false)

  const visibleFields = fields.filter(
    (field) => !field?.className?.includes('hidden')
  )

  return (
    <Form
      className={classnames('flex flex-col justify-start items-start my-6', {
        'sm:flex-row sm:flex-nowrap': visibleFields.length < 2,
      })}
      fields={fields}
      onSubmit={onSubmit}
      onChange={({ isFormValid }) => {
        setIsFormValid(isFormValid)
      }}
    >
      <Button
        size="l"
        corners="rounded"
        colour="pitaya"
        disabled={!isFormValid}
        loading={processing}
        className={classnames('m-0 w-full sm:w-auto', {
          'ml-auto': visibleFields.length >= 2,
          'sm:mt-3 sm:ml-4': visibleFields.length < 2,
        })}
      >
        Submit
      </Button>
    </Form>
  )
}
